'use strict';



class ModalYesNo extends React.Component {
  constructor(props) {
    super(props);
  }
   
  render() {
    return <div className="modal fade" id="modalYesNo" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="yesNoModalLabel">Modal Header</h4>
            <button type="button" className="close" data-dismiss="modal">x</button>
          </div>
          <div className="modal-body" id="bodyModalYesNo">body</div>
          <div className="modal-footer">
            <button type="button" id="btnOkModalYesNo" className="btn btn-primary btn-ok" data-dismiss="modal">Ok</button>
            <button type="button" id="btnCancelModalYesNo" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  }
}

$(function() {
  const domContainerModalYesNo= document.querySelector('#modalYesNoContainer');
  document.body.appendChild(domContainerModalYesNo);
  ReactDOM.render(<ModalYesNo></ModalYesNo>, domContainerModalYesNo);
});